<template>
  <div>
    <v-col
      cols="11"
      class="pa-0 mt-10 pt-10 mx-auto text-left min-h-screen"
      v-if="items.length > 0">
      <v-row justify="space-between" class="px-3 mb-5">
        <v-col cols="4"> </v-col>
        <v-col cols="8">
          <div class="font-bold text-2xl sm:text-4xl pa-0 mt-5" v-if="bahasa">
            Program Tematik
          </div>
          <div class="font-bold text-2xl sm:text-4xl pa-0 mt-5" v-else>
            Thematic Program
          </div>
        </v-col>
      </v-row>
      <v-row justify="space-between" class="px-3">
        <v-col cols="12" sm="12" md="4">
          <div class="font-bold text-xl sm:text-2xl mb-2" v-if="bahasa">
            Daftar Isi
          </div>
          <div class="font-bold text-xl sm:text-2xl mb-2" v-else>
            Table of Contents
          </div>
          <v-skeleton-loader
            v-if="isLoad"
            type="paragraph"
            class="mt-5"></v-skeleton-loader>
          <v-treeview
            v-else
            v-model="tree"
            :open.sync="open"
            :items="items"
            :active.sync="active"
            activatable
            return-object
            item-key="name"
            open-on-click
            :hoverable="false"
            class="guideline-tree"
            active-class="guideline-tree-active">
          </v-treeview>
        </v-col>
        <v-col cols="12" sm="12" md="8" class="mb-10">
          <v-scroll-y-transition mode="out-in">
            <v-col cols="12" class="pa-0">
              <div v-if="active.length === 0">
                <div class="text-black mb-3 pb-0 text-xl sm:text-2xl font-bold">
                  Overview
                </div>
              </div>
              <v-card v-else class="pt-0" flat>
                <v-card-text class="pt-0 px-0">
                  <div
                    class="text-black mb-0 pb-0 text-xl sm:text-2xl font-bold">
                    {{ active[0].name }}
                  </div>
                  <img
                    v-if="active[0].desc"
                    :src="baseUrlImage + active[0].desc.image.file_path"
                    @error="
                      $event.target.src =
                        'https://admin.bpdlh.id/assets/empty.png'
                    "
                    width="100%"
                    class="rounded-md mt-8" />
                  <v-skeleton-loader
                    v-if="isLoadDetail"
                    type="paragraph"
                    class="mt-5"></v-skeleton-loader>
                  <div v-if="active[0].desc !== null">
                    <div
                      xs12
                      class="text-black pt-5 px-0"
                      v-html="active[0].desc.desc"
                      v-if="is_expand"></div>
                    <div
                      xs12
                      class="text-black pt-5 px-0"
                      v-html="strLimit(active[0].desc.desc, 500)"
                      v-else></div>
                    <div
                      class="text-primary cursor-pointer font-bold mt-3 mb-2"
                      @click="is_expand = !is_expand"
                      v-if="is_expand && active[0].desc.desc.length > 500">
                      <u v-if="bahasa">Sembunyikan Detail</u>
                      <u v-else>Collapse Details</u>
                      <v-icon small color="green darken-1">expand_less</v-icon>
                    </div>
                    <div
                      class="text-primary cursor-pointer font-bold mt-3 mb-2"
                      @click="is_expand = !is_expand"
                      v-else-if="
                        !is_expand && active[0].desc.desc.length > 500
                      ">
                      <u v-if="bahasa">Perluas Detail</u>
                      <u v-else>Expand Details</u>
                      <v-icon small color="green darken-1">expand_more</v-icon>
                    </div>
                  </div>
                  <div
                    class="text-black mt-6 mb-6 pb-0 text-xl sm:text-2xl font-bold"
                    v-if="active[0].desc.program.length > 0">
                    {{ bahasa ? "Program" : "Programs" }}
                  </div>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      v-for="(item, index) in active[0].desc.program"
                      :key="index"
                      class="cursor-pointer"
                      @click="goToProgram('program-detail', item)">
                      <v-row>
                        <v-col cols="auto" class="pt-4">
                          <img
                            src="@/assets/images/icon_program.svg"
                            width="80%" />
                        </v-col>
                        <v-col cols="10" class="text-black">
                          <div class="font-bold text-xl py-0">
                            {{ item.name || "-" }}
                          </div>
                          <div class="text-sm py-3">
                            {{ item.createdDate || "-" }}
                          </div>
                          <!-- <div class="font-lora text-base">USD {{ item.donor.length > 0 ? item.donor[0].fund : '-' }}</div> -->
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <div class="px-0 pt-10 pb-5 mt-5" v-if="stories.length > 0">
                    <v-col cols="12" class="block m-auto px-0">
                      <div
                        class="font-bold text-base sm:text-xl mb-8 text-left text-black">
                        <span v-if="bahasa">Kisah & Blog</span>
                        <span v-else>Stories & Blogs</span>
                      </div>
                      <slick
                        ref="slick"
                        :options="slickOptionsStory"
                        class="px-2">
                        <div
                          v-for="(i, index) in stories"
                          :key="index"
                          @click="
                            goTo(
                              'content-detail',
                              i.id,
                              i.category.toLowerCase()
                            )
                          "
                          class="cursor-pointer mb-3 pa-1 text-left">
                          <v-img
                            :src="baseUrlImage + i.image.file_path"
                            class="rounded align-center pa-5"
                            height="250">
                            <v-col
                              cols="12"
                              class="rounded-md border border-white-500 pa-5 bg-blur text-white">
                              <div class="text-sm">{{ i.category || "-" }}</div>
                              <div
                                class="py-2"
                                v-html="strLimit(i.title, 40)"></div>
                              <v-btn
                                class="btn-primary-white mt-3 font-bold text-capitalize">
                                <span v-if="bahasa">selengkapnya</span>
                                <span v-else>Read More</span>
                              </v-btn>
                            </v-col>
                          </v-img>
                        </div>
                      </slick>
                      <button class="btn-white-outlined nextStory-btn">
                        <v-icon color="black">arrow_back</v-icon>
                      </button>
                      <button class="btn-white-outlined backStory-btn">
                        <v-icon color="black">arrow_forward</v-icon>
                      </button>
                    </v-col>
                  </div>
                  <div
                    class="text-black mt-10 mb-8 pb-0 text-xl sm:text-2xl font-bold"
                    v-if="news.length > 0">
                    {{ bahasa ? "Berita" : "News" }}
                  </div>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      class="cursor-pointer mb-3 text-left text-black"
                      v-for="(berita, index) in news"
                      :key="index + 'news'"
                      @click="goToProgram('news-detail', berita)">
                      <v-row>
                        <v-col cols="3" class="px-0">
                          <img
                            :src="baseUrlImage + berita.image.file_path"
                            @error="
                              $event.target.src =
                                'https://admin.bpdlh.id/assets/empty.png'
                            "
                            width="100%"
                            class="rounded-md sm:px-3" />
                        </v-col>
                        <v-col cols="9">
                          <div class="font-bold text-base pt-0 sm:text-xl">
                            {{ berita.title || "-" }}
                          </div>
                          <div class="text-sm py-2 pa-0">
                            {{ berita.date || "-" }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-scroll-y-transition>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="11" class="pa-0 mt-10 py-10" v-else>
      <v-row>
        <v-col cols="12" sm="6">
          <img src="@/assets/images/405.png" width="100%" />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="text-black flex flex-wrap content-center">
          <v-col cols="12" class="text-3xl font-bold pl-0">{{
            bahasa
              ? "Tidak Ada Data Layanan yang Tersedia"
              : "No Service Data Available"
          }}</v-col>
          <v-btn class="btn-primary mt-8" :to="{ name: 'home' }">
            {{ bahasa ? "kembali ke beranda" : "back to home" }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Slick from "vue-slick";
import "slick-carousel/slick/slick.css";
export default {
  components: {
    Slick,
  },
  props: {
    bahasa: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      slickOptionsStory: {
        slidesToShow: 3,
        prevArrow: ".nextStory-btn",
        nextArrow: ".backStory-btn",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      is_expand: false,
      page: 1,
      pageCount: 0,
      isLoad: false,
      isLoadDetail: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlImage: process.env.VUE_APP_BASE_ASSET,
      scrollSettings: {
        maxScrollbarLength: 60,
      },
      progressDialogLoading: false,
      active: [],
      open: [],
      tree: [],
      items: [],
      news: [],
      stories: [],
    };
  },
  watch: {
    open(val) {
      if (val.length > 1) {
        this.open.splice(0, 1);
      }
    },
    language() {
      this.getData();
    },
    active(val) {
      if (val.length === 0) {
        this.active = [this.items[0]];
      }
      this.getDetail(val[0].id);
    },
  },
  computed: {
    language() {
      return this.bahasa ? "ID" : "ENG";
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    goToProgram(link, item) {
      this.$router.push({
        name: link,
        params: {
          id: item.id,
        },
      });
    },
    goTo(link, param, type) {
      this.$router.push({
        name: link,
        params: {
          id: param,
          type: "/" + type,
        },
      });
    },
    async getData() {
      this.isLoad = true;
      await axios
        .get(this.baseUrl + "/thematic_program", {
          params: { lang: this.language },
        })
        .then((response) => {
          this.items = response.data.data.data;
          this.active = [this.items[0]];
          this.getDetail(this.active[0].id);
          this.page = response.data.data.current_page;
          this.pageCount = response.data.data.last_page;
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoad = false));
    },
    async getDetail(id) {
      this.isLoadDetail = true;
      await axios
        .get(this.baseUrl + "/thematic_program/" + id, {
          params: { lang: this.language },
        })
        .then((response) => {
          this.active[0].desc = response.data.data;
          this.stories = response.data.data.news.filter((item) => {
            return item.category === "Blog" || item.category === "Kisah";
          });
          this.news = response.data.data.news.filter((item) => {
            return item.category === "News" || item.category === "Berita";
          });
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadDetail = false));
    },
  },
};
</script>
<style>
.drawer-menu--scroll-guideline {
  max-height: 380px;
  overflow: auto;
}
.drawer-menu--scroll-guideline-menu {
  max-height: 450px;
  overflow: auto;
}
@media (min-height: 768px) {
  .drawer-menu--scroll-guideline {
    max-height: 500px !important;
    overflow: auto;
  }
  .drawer-menu--scroll-guideline-menu {
    max-height: 570px !important;
    overflow: auto;
  }
}
</style>
